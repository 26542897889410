import React, { useState } from "react";
import "./Videocall.scss";
import tooths from "../../../Assets/Images/tooth.png";
import hearts from "../../../Assets/Images/heart-attack.png";
import leg_pains from "../../../Assets/Images/pain.png";
import stomacks from "../../../Assets/Images/stomach.png";
import ears from "../../../Assets/Images/ear.png";
import brains from "../../../Assets/Images/brain.png";
import Tooth from "../Videocall/Tooth";
import Leg from "../Videocall/Leg";
import Stomach from "../Videocall/Stomach";
import Brain from "../Videocall/Brain";
import Heart from "../Videocall/Heart";
import Ear from "../Videocall/Ear";
import makeStyles from "@mui/styles/makeStyles";
import hamburger from "../../../Assets/Images/menu.png";

const Videocallpages = {
  ear: 0,
  tooth: 1,
  leg: 2,
  stomach: 3,
  brain: 4,
  heart: 5,
};

function getvideoscreen(Videocallpage) {
  let selectedvideoTab = null;

  switch (Videocallpage) {
    case Videocallpages.ear:
      selectedvideoTab = <Ear />;
      break;
    case Videocallpages.tooth:
      selectedvideoTab = <Tooth />;
      break;
    case Videocallpages.leg:
      selectedvideoTab = <Leg />;
      break;
    case Videocallpages.stomach:
      selectedvideoTab = <Stomach />;
      break;
    case Videocallpages.brain:
      selectedvideoTab = <Brain />;
      break;
    default:
      selectedvideoTab = <Heart />;
      break;
  }

  return selectedvideoTab;
}

const useStyles = makeStyles((theme) => ({
  links: {
    textDecoration: "none",
    backgroundColor: "#825EF6",
    color: "white",
    border: "2px solid blue",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
  },
  inactive: {
    textDecoration: "none",
    color: "grey",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
  },
}));

const VideoCall = () => {
  const classes = useStyles();
  const [secleted_videoTap, setsecleted_videoTap] = useState(
    Videocallpages.ear
  );

  const handleClick = (secleted_videoTap) => {
    setsecleted_videoTap(secleted_videoTap);
  };

  return (
    <div className="videocall">
      <div className="container">
        <div className="tabs_buttions row">
          <div className="col-md-1">
            <img
              src={ears}
              alt="ear"
              className={
                Videocallpages.ear === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.ear)}
            />
          </div>
          <div className="col-md-1">
            <img
              src={tooths}
              alt="teeth"
              className={
                Videocallpages.tooth === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.tooth)}
            />
          </div>

          <div className="col-md-1">
            <img
              src={leg_pains}
              alt="leg"
              className={
                Videocallpages.leg === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.leg)}
            />
          </div>
          <div className="col-md-1">
            <img
              src={stomacks}
              alt="stomach"
              className={
                Videocallpages.stomach === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.stomach)}
            />
          </div>
          <div className="col-md-1">
            <img
              src={brains}
              alt="brains"
              className={
                Videocallpages.brain === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.brain)}
            />
          </div>
          <div className="col-md-1">
            <img
              src={hearts}
              alt="hearts"
              className={
                Videocallpages.heart === secleted_videoTap
                  ? classes.links
                  : classes.inactive
              }
              onClick={() => handleClick(Videocallpages.heart)}
            />
          </div>
          <div className="offset-md-2 col-md-1 menu_1">
            <img src={hamburger} alt="menu"></img>
          </div>
        </div>
        <div className="row main_video">
          <div className="col-md-12 mt-5">
            {getvideoscreen(secleted_videoTap)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
