import { BrowserRouter as Router } from "react-router-dom";
import { Router as Routes } from "../Routes/Routes";
import "../Assets/Styles/App/App.css";
import { Theme } from "../Config/Theme";

const App = () => {
  return (
    <>
      <Theme>
        <Router>
          <Routes />
        </Router>
      </Theme>
    </>
  );
};

export default App;
