import React from "react";
import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  appointments: {
    height: "350px",

    borderRadius: "10px !important",
  },
}));

const CalendarCard = () => {
  const classes = useStyles();
  return <Paper className={classes.appointments} elevation={2}></Paper>;
};

export default CalendarCard;
