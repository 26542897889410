import React from "react";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { TableVirtuoso } from "react-virtuoso";

const useStyles = makeStyles((theme) => ({
  button: {
    borderWidth: "2px !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "10px 30px !important",
  },
  paper: {
    padding: "20px",
    borderRadius: "10px !important",
  },
  TableCell: {
    padding: "3px 20px !important",
  },
}));

const columns = [
  {
    // width: 150,
    label: "Name",
    dataKey: "dessert",
    image: true,
  },
  {
    // width: 80,
    label: "Gender",
    dataKey: "calories",
    numeric: true,
  },
  {
    // width: 80,
    label: "Weight",
    dataKey: "fat",
    numeric: true,
  },
  {
    // width: 80,
    label: "Disease",
    dataKey: "carbs",
    numeric: true,
  },
  {
    // width: 80,
    label: "Date",
    dataKey: "protein",
    numeric: true,
  },
  {
    // width: 80,
    label: "Status",
    dataKey: "protein",
    numeric: true,
  },
];

const rows = [
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width }}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "none",
            color: "gray",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

const RecentPatients = () => {
  const classes = useStyles();

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell key={column.dataKey} className={classes.TableCell}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {column.image || false ? (
                <Avatar
                  color="primary"
                  sx={{
                    mr: "20px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                ""
              )}
              {row[column.dataKey]}
            </Box>
          </TableCell>
        ))}
      </React.Fragment>
    );
  }
  return (
    <Paper elevation={2} className={classes.paper} sx={{ mb: "30px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" fontWeight="600">
          Recent Patients
        </Typography>
        <Typography
          variant="p"
          fontWeight="500"
          color="primary"
          sx={{ cursor: "pointer" }}
        >
          See All
        </Typography>
      </Box>
      <div style={{ height: 290, width: "100%" }}>
        <TableVirtuoso
          data={rows}
          className="scrollhost"
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </div>
    </Paper>
  );
};

export default RecentPatients;
