import { Box } from "@mui/material";
import React from "react";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const Documents = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextSnippetIcon sx={{ mr: "15px" }} color="primary" />
        <h3>Document File</h3>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SmartDisplayIcon sx={{ mr: "15px" }} color="primary" />
        <h3>Recordings</h3>
      </Box>
    </Box>
  );
};

export default Documents;
