import React from "react";
import { Box, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MedicationIcon from "@mui/icons-material/Medication";
import { makeStyles } from "@mui/styles";
import { TableVirtuoso } from "react-virtuoso";

const useStyles = makeStyles((theme) => ({
  button: {
    borderWidth: "2px !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "10px 30px !important",
  },
  TableCell: {
    padding: "5px 16px !important",
  },
}));

const columns = [
  {
    // width: 130,
    label: "Disease",
    dataKey: "dessert",
    image: true,
  },
  {
    // width: 100,
    label: "Doctor",
    dataKey: "calories",
    numeric: true,
  },
  {
    // width: 100,
    label: "Date",
    dataKey: "fat",
    numeric: true,
  },
  {
    // width: 100,
    label: "Detail",
    dataKey: "carbs",
    numeric: true,
  },
  {
    // width: 100,
    label: "Duration",
    dataKey: "protein",
    numeric: true,
  },
];

const rows = [
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width }}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "none",
            color: "gray",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

const Prescription = () => {
  const classes = useStyles();

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell key={column.dataKey} className={classes.TableCell}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {column.image || false ? (
                <MedicationIcon
                  color="primary"
                  sx={{
                    mr: "20px",
                    padding: "10px",
                    backgroundColor: "rgba(80, 77, 168, 0.14)",
                    borderRadius: "10px",
                    fontSize: "2.5rem !important"
                  }}
                />
              ) : (
                ""
              )}
              {row[column.dataKey]}
            </Box>
          </TableCell>
        ))}
      </React.Fragment>
    );
  }
  return (
    <>
      <div style={{ height: 280, width: "100%" }}>
        <TableVirtuoso
          className="scrollhost"
          data={rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </div>
      <Box textAlign="center" mt={1}>
        <Button
          startIcon={<AddCircleIcon />}
          color="secondary"
          variant="outlined"
          className={classes.button}
        >
          Add Prescription
        </Button>
      </Box>
    </>
  );
};

export default Prescription;
