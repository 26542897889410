import {
  Grid,
  Box,
  Paper,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const useStyles = makeStyles((theme) => ({
  people: {
    // height: "600px",
    borderRadius: "10px !important",
  },
  appointments: {
    height: "300px",
    borderRadius: "10px !important",
  },
  appointmentsList: {
    borderLeft: "10px solid #504DA6",
    borderRadius: "10px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 30px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  calls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#504DA6",
    color: "white",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
  },
  messages: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px solid #504DA6",
    color: "#504DA6",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 13,
  width: 350,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 13,
    backgroundColor: theme.palette.mode === "light" ? "primary" : "primary",
  },
}));

const People = () => {
  const classes = useStyles();
  return (
    <Paper elevation={2} className={classes.people}>
      <Box p={5}>
        <Typography fontWeight="500" color="primary" mb={1} fontSize={30}>
          150 People
        </Typography>
        <Typography
          variant="p"
          fontWeight="500"
          color="black"
          sx={{
            marginBottom: "10px",
            display: "block",
          }}
        >
          Appointments Limit
        </Typography>
        <Box className={classes.flex}>
          <BorderLinearProgress variant="determinate" value={40} />
          <Typography variant="p" fontWeight="500" color="black">
            150/300
          </Typography>
        </Box>
        <Divider sx={{ mt: "20px" }} />

        <Grid container mb={4} mt={4} rowGap={5}>
          <Grid item xs={6} align="left">
            <Typography variant="h4" fontWeight="500" color="primary" mb={1}>
              2.3456
            </Typography>
            <Typography variant="p" fontWeight="500" color="black" mb={1}>
              Appointments
            </Typography>
          </Grid>
          <Grid item xs={6} align="center">
            <Typography variant="h4" fontWeight="500" color="primary" mb={1}>
              3.4456
            </Typography>
            <Typography variant="p" fontWeight="500" color="black" mb={1}>
              Total Patients
            </Typography>
          </Grid>
          <Grid item xs={6} align="left">
            <Typography variant="h4" fontWeight="500" color="primary" mb={1}>
              13.4762
            </Typography>
            <Typography variant="p" fontWeight="500" color="black" mb={1}>
              Consultations
            </Typography>
          </Grid>
          <Grid item xs={6} align="center">
            <Typography variant="h4" fontWeight="500" color="primary" mb={1}>
              3.4212
            </Typography>
            <Typography variant="p" fontWeight="500" color="black" mb={1}>
              Return Patients
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ m: "20px 0px" }} />
        <Grid container spacing={10} pt={2}>
          <Grid item xs={6}>
            <Box className={classes.calls}>
              <Typography variant="h4">18</Typography>
              <Typography variant="p" p={1}>
                Missed Calls
              </Typography>
              <PhoneMissedIcon />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.messages}>
              <Typography variant="h4">9</Typography>
              <Typography variant="p" p={1}>
                Messages
              </Typography>
              <EmailOutlinedIcon />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default People;
