import React from "react";

import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
export const Theme = ({ children }) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#504DA6",
      },
      secondary: {
        main: "#43D2D8",
      },
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
  });

  theme = responsiveFontSizes(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
