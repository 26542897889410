import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import { makeStyles } from "@mui/styles";
import Header from "../../Common/Header";
import logo from "../../../Assets/Images/Image 5.png";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlink: {
    textDecoration: "none",
    position: "relative",
    margin: "auto",
    display: "flex",
    padding: "15px",
    justifyContent: "start",
    alignItems: "center",
    textTransform: "none",
    color: "black",
  },
  active: {
    textDecoration: "none",
    position: "relative",
    margin: "auto",
    display: "flex",
    padding: "15px",
    justifyContent: "start",
    alignItems: "center",
    textTransform: "none",
    color: "blue",
  },
  grid: {
    height: "100vh",
    boxShadow: " 1px 0 10px -2px #888",
  },
  Outlet: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  logo: {
    padding: "20px 50px",
    width: "150px",
    cursor: "pointer",
  },
}));

const menuOptions = [
  {
    title: "Dashboard",
    path: "dashboard",
    icon: <WidgetsRoundedIcon style={{ opacity: "40%", fontSize: "25" }} />,
  },
  {
    title: "Schedule",
    path: "schedule",
    icon: (
      <CalendarMonthRoundedIcon style={{ opacity: "40%", fontSize: "25" }} />
    ),
  },
  {
    title: "Patients",
    path: "patients",
    icon: <PersonRoundedIcon style={{ opacity: "40%", fontSize: "25" }} />,
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  useEffect(() => {
    navigate("/sidebar/dashboard");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container sx={{ display: "flex", flexWrap: "nowrap" }}>
      <Grid item className={classes.grid} sx={{ width: "200px" }}>
        <Box className={classes.logo} onClick={() => navigate("/")}>
          <img src={logo} alt="logo" width="100" />
        </Box>
        {menuOptions.map((item, index) => {
          return (
            <Box key={index} sx={{ p: "0px 20px" }}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.active : classes.navlink
                }
                to={item.path}
              >
                <Box sx={{ paddingRight: "25px" }}>{item.icon}</Box>
                {item.title}
              </NavLink>
            </Box>
          );
        })}
      </Grid>
      <main className={classes.Outlet}>
        <Header />
        <Outlet />
      </main>
    </Grid>
  );
};

export default Sidebar;
