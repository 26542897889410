import { Box, Grid, Paper, Button, Input } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import logo from "../../../../Assets/Images/Image 5.png";
import hero from "../../../../Assets/Images/hero.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  box: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  forgot: {
    margin: "20px 0",
    fontSize: "12px",
    color: "#504DA6",
    position: "relative",
    cursor: "pointer",
    left: "30%",
  },
  input: {
    backgroundColor: "#F9F9F9",
    borderRadius: "10px",
    margin: "10px 0",
    padding: "8px 20px",
  },
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  //   const token = localStorage.getItem("token");
  const token = false;

  useEffect(() => {
    if (token) navigate("/dashboard");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={5} xs={12} className={classes.box}>
          <Box className={classes.box}>
            <Paper
              elevation={3}
              style={{ borderRadius: "33px", padding: "15px" }}
              className={classes.box}
            >
              <img src={logo} alt="logo" />
            </Paper>
            <h2 style={{ padding: "20px 0", width: "450px" }}>
              <span style={{ color: "#43D2D8" }}>New</span>{" "}
              <span style={{ color: "#825EF6" }}>Password</span>{" "}
            </h2>

            <Input
              disableUnderline
              className={classes.input}
              fullWidth
              placeholder="Password"
            ></Input>
            <Input
              disableUnderline
              className={classes.input}
              fullWidth
              placeholder="Confirm Password"
            ></Input>

            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "#43D2D8",
                mt: 3,
                "&:hover": { backgroundColor: "#43D2D8" },
              }}
              onClick={() => navigate("/sidebar")}
            >
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ display: { xs: "none", md: "flex" } }}
          bgcolor="#825EF6"
          className={classes.box}
        >
          <Box>
            <img src={hero} alt="hero" width="60%" height="60%" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
