import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Profile from "./Tabs/Profile";
import Clinic from "./Tabs/Clinic";
import Prescription from "./Tabs/Prescription";
import Document from "./Tabs/Documents";

const ProfilePages = {
  profile: 0,
  clinical: 1,
  prescriptions: 2,
  documents: 3,
};

function getProfilePage(profilePage) {
  let selectedComponent = null;
  switch (profilePage) {
    case ProfilePages.profile:
      selectedComponent = <Profile />;
      break;
    case ProfilePages.clinical:
      selectedComponent = <Clinic />;
      break;
    case ProfilePages.prescriptions:
      selectedComponent = <Prescription />;
      break;
    default:
      selectedComponent = <Document />;
      break;
  }
  return selectedComponent;
}

const useStyles = makeStyles((theme) => ({
  links: {
    textDecoration: "none",
    backgroundColor: "#825EF6",
    padding: "5px  15px",
    color: "white",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
  },
  inactive: {
    textDecoration: "none",
    padding: "5px  15px",
    border: "1px solid grey",
    color: "grey",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
  },
}));

const Screen = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(ProfilePages.profile);

  const handleClick = (selectedTab) => {
    setSelectedTab(selectedTab);
  };

  return (
    <>
      <Box>
        <span
          className={
            ProfilePages.profile === selectedTab
              ? classes.links
              : classes.inactive
          }
          onClick={() => handleClick(ProfilePages.profile)}
        >
          Profile
        </span>
        <span
          className={
            ProfilePages.clinical === selectedTab
              ? classes.links
              : classes.inactive
          }
          onClick={() => handleClick(ProfilePages.clinical)}
        >
          Clinical Card
        </span>
        <span
          className={
            ProfilePages.prescriptions === selectedTab
              ? classes.links
              : classes.inactive
          }
          onClick={() => handleClick(ProfilePages.prescriptions)}
        >
          Presscriptions
        </span>
        <span
          className={
            ProfilePages.documents === selectedTab
              ? classes.links
              : classes.inactive
          }
          onClick={() => handleClick(ProfilePages.documents)}
        >
          Douments
        </span>
      </Box>
      <Box mt={5}>{getProfilePage(selectedTab)}</Box>
    </>
  );
};

export default Screen;
