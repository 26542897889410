import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import Dashboard from "../Components/Pages/Dashboard/Dashboard";
import Schedule from "../Components/Pages/Schedule/Schedule";
import Patient from "../Components/Pages/Patient/Patient";
import PatientSceens from "../Components/Pages/Patient/PatientTabs/HeroCard";
import VideoCall from "../Components/Pages/Videocall/VideoCall";
import Login from "../Components/Pages/Login/Login";
import SignUp from "../Components/Pages/Login/SignUp";
import ForgotPassword from "../Components/Pages/Login/ForgoutPassword/ForgotPassword";
import { ProtectedRoutes } from "../utils/ProtectedRoutes";
import ResetPassword from "../Components/Pages/Login/ForgoutPassword/ResetPassword";

export const Router = () => {
  return (
    <>
      <Switch>
        <Route path="" exact element={<Login />} />
        <Route path="signup" exact element={<SignUp />} />
        <Route path="forgot" exact element={<ForgotPassword />} />
        <Route path="reset" exact element={<ResetPassword />} />
        <Route path="/sidebar" element={<ProtectedRoutes />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="patients" element={<Patient />} />
          <Route path="patientId" element={<PatientSceens />} />
          <Route path="video" element={<VideoCall />} />
        </Route>
      </Switch>
    </>
  );
};
