import React from "react";
import video_call from "../../../Assets/Images/video_doctor.avif";

const Brain = () => {
  return (
    <div>
      Brain
      <img src={video_call} alt="video"></img>
    </div>
  );
};

export default Brain;
