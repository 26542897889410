import { Paper, Container, Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CallIcon from "@mui/icons-material/Call";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Screen from "./Screen";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px !important",
    padding: "25px",
  },
  diagnoses: {
    marginTop: "20px",
    borderRadius: "10px !important",
    padding: "25px",
    // height: "300px",
    overflow: "auto",
  },
  image: {
    ObjectFit: "cover",
    borderRadius: "20px",
    backgroundColor: "#504DA6",
    height: "130px",
    width: "130px",
  },
  h2: {
    margin: "0px",
  },
  button: {
    borderRadius: "12px !important",
    padding: "5px 30px !important",
    textTransform: "none !important",
    backgroundColor: "#43D2D8 !important",
    justifyContent: "center !important",
  },
  icons: {
    borderRadius: "50%",
    padding: "10px",
    backgroundColor: "#504DA6",
    color: "white",
    marginRight: "10px",
    cursor: "pointer",
    fontSize: "2.5rem !important",
  },
  disease: {
    height: "70px",
    borderRadius: "10px !important",
    padding: "10px",
  },
}));
const Screens = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "50px",
        display: "flex",
        gap: "50px",
        overflow: "auto",
        height: "87vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", lg: "55%" } }}>
          <Paper
            className={classes.paper}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img src="" alt="hero" className={classes.image} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "80%",
              }}
            >
              <Box ml={2}>
                <h2 className={classes.h2}>Farah Alem</h2>
                <p className={classes.h2}>Health checkup</p>
                <p className={classes.h2}>Health checkup</p>
              </Box>
              <Box>
                <VideoCallIcon
                  onClick={() => navigate("/sidebar/video")}
                  className={classes.icons}
                />
                <CallIcon className={classes.icons} />
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{ mt: "20px", minHeight: "400px" }}
            elevation={2}
            className={classes.paper}
          >
            <Screen />
          </Paper>
        </Box>
        <Box sx={{ width: { xs: "100%", lg: "45%" } }} pr={3}>
          <Paper elevation={2} className={classes.paper}>
            <h3 className={classes.h2}>Notes</h3>
            <p>
              Dolor ad ad aliqua qui ea qui duis. Laborum ullamco nisi fugiat
              amet excepteur minim eiusmod velit ut. Do nulla exercitation sit
              tempor pariatur magna ullamco cillum consequat consectetur nostrud
              consequat elit. Amet voluptate sunt laboris labore laborum nostrud
              occaecat sint anim cillum in aute.
            </p>
            <Box textAlign="center">
              <Button variant="contained" className={classes.button}>
                Save
              </Button>
            </Box>
          </Paper>
          <Paper elevation={2} className={classes.diagnoses}>
            <h3 style={{ marginBottom: "20px" }}>Latest Diagnoses</h3>
            <Grid container gap={5}>
              <Grid item xs={5}>
                <Paper className={classes.disease}>pnemonia</Paper>
              </Grid>
              <Grid item xs={5}>
                <Paper className={classes.disease}>stomach ache</Paper>
              </Grid>
              <Grid item xs={5}>
                <Paper className={classes.disease}>broken bone</Paper>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default Screens;
