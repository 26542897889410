import * as React from "react";
import { TableVirtuoso } from "react-virtuoso";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  Avatar,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import "../../../Assets/Styles/Common/Patients.css";
import { useNavigate } from "react-router-dom";
import "../../../Assets/Styles/App/App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: " 30px",
    borderRadius: "20px !important",
    padding: "30px",
  },
  h1: {
    margin: 0,
    fontWeight: "600",
  },
}));

const columns = [
  {
    // width: 200,
    label: "Name",
    dataKey: "dessert",
    image: true,
  },
  {
    // width: 100,
    label: "Gender",
    dataKey: "calories",
    numeric: true,
  },
  {
    // width: 100,
    label: "Weight",
    dataKey: "fat",
    numeric: true,
  },
  {
    // width: 100,
    label: "Disease",
    dataKey: "carbs",
    numeric: true,
  },
  {
    // width: 100,
    label: "Date",
    dataKey: "protein",
    numeric: true,
  },
  {
    // width: 100,
    label: "Status",
    dataKey: "protein",
    numeric: true,
  },
];

const rows = [
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
  {
    id: 1,
    dessert: "Yogert",
    calories: 123,
    fat: 123,
    carbs: 123,
    protein: 123,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const Patient = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
              borderBottom: "none",
              color: "gray",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/sidebar/patientId")}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {column.image || false ? (
                <Avatar
                  color="primary"
                  sx={{
                    mr: "50px",
                    padding: "5px",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                ""
              )}
              {row[column.dataKey]}
            </Box>
          </TableCell>
        ))}
      </React.Fragment>
    );
  }
  return (
    <div className="patent">
      <Paper className={classes.root}>
        <h1 className={classes.h1}>Recent Patients</h1>
        <Box sx={{ height: "70vh" }}>
          <TableVirtuoso
            className="scrollhost"
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default Patient;
