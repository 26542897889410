import React from "react";
import { Navigate } from "react-router-dom";
import SideBar from "../Components/Pages/Sidebar/Sidebar";

export const ProtectedRoutes = () => {
  // const token = localStorage.get("token");
  const token = true;

  return !token ? <Navigate to="/login" replace /> : <SideBar />;
};
