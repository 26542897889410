import { Badge, Calendar } from 'antd';
import React, { useEffect, useState } from 'react';
import '../Schedule/Schedule.scss';
import avatar from '../../../Assets/Styles/image/profile.png'

const getListData = (value) => {

  let listData = "";

  switch (value.date()) {
    case 8:
      listData = [
        {
          id: "1",
          key: "1",
          time: '10',
          content: 'Health 1',
          Name: 'sagheen'
        },

      ];
      break;
    case 10:
      listData = [
        {
          id: "2",
          key: "2",
          time: '10',
          content: 'Health 2',
          Name: 'sagheen'
        }
      ];
      break;
    case 15:
      listData = [
        {
          id: "5",
          key: "5",
          time: '10',
          content: 'Health 3',
          Name: 'sagheen'
        }

      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value) => {
  if (value.month() === 12) {
    return;
  }
};

const Schedule = () => {

  const [toggle, setToggle] = useState([]);

  const [data, setData] = useState({})

  const dateCellRender = (value) => {

    const listData = getListData(value);
    // console.log(listData);

    return (
      <ul className="events">
        {
          listData.map((item, key) =>
            <li>
              <Badge onClick={() => setToggle(item)} status={item.time} text={item.content} name={item.Name} />
            </li>
          )
        }
      </ul>

    );
  };
  useEffect(() => {
    // dateCellRender(toggle)
    // let arr = data;
    console.log('data', data)
    // arr.push(toggle)
    setData(toggle)
    console.log('toggle', toggle)
  }, [toggle])

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };


  return (
    <div className='calenter'>
      <div className='row callenter_main'>
        <div className='col-offset-md-1 col-md-7'>
          <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />;
        </div>
        <div className='col-md-4'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='title_callenter'>
                <div className='child_colenter'>
                  <h4>Upcoming Schedule</h4>
                  <p>Your Upcoming schedule with Patients</p>
                </div>
                <h5>...</h5>
              </div>
            </div>
            <div className='col-md-12 doctor_details'>
              <div className='main'>
                {Object.keys(data)?.length === 1 ?
                  null :
                  <>
                    <div className='cart_detatils'>
                      <div className='profile'>
                        <img src={avatar} alt="profile" width="50px" />
                        <div className='profilr_details'>
                          <h5>{data?.name}</h5>
                          <p> Electra street, Abu dhabi </p>
                        </div>
                      </div>
                    </div>
                    <div className='cart_time'>
                      <p>Time</p>
                      <div className='dates'>
                        <p>Monday,20 March 2022</p>
                        <div className='time'>
                          <p>10am - 11am</p>
                        </div>
                      </div>
                    </div>
                    <div className='cart_service'>
                      <p>Service</p>
                      <div className='servicess'>
                        <p>{data?.content}</p>
                      </div>
                    </div>
                    <div className='cart_notes'>
                      <p>Notes</p>
                      <div className='notes_review'>
                        Hi Doc, I want Health Consulation Don't forget to come One time i realy appracite you, Thanks
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Schedule;

