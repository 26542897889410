import React from "react";
import { Container, Grid, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import People from "./Sections/PeopleCard";
import Appointments from "./Sections/Appointments";
import RecentPatients from "./Sections/RecentPatients";
import Calendar from "./Sections/Calendar";

const useStyles = makeStyles((theme) => ({
  doctor: {
    backgroundColor: "#504DA6",
    borderRadius: "10px",
    height: "150px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
  },
  people: {
    height: "600px",
    width: "100%",
    borderRadius: "10px !important",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  p: {
    marginTop: "1px",
    fontSize: "12px",
    color: "white",
  },
  h1: {
    marginBottom: "5px",
    marginTop: "2px",
    color: "white",
    fontWeight: "500",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "50px",
        display: "flex",
        gap: "50px",
        overflow: "auto",
        height: "87vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", lg: "60%" } }}>
          <Grid container gap={5}>
            <Grid item xs={12} pl={3}>
              <Box className={classes.doctor}>
                <Box>
                  <h2 className={classes.h1}>Hello Dr. Jackson Santos</h2>
                  <p className={classes.p}>
                    Here are your important tasks and reports. <br /> Please
                    check the next Appointments
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} pl={3}>
              <Appointments />
            </Grid>
            <Grid item xs={12} pl={3}>
              <RecentPatients />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: { xs: "100%", lg: "40%" } }} pr={3}>
          <Grid container gap={5}>
            <Grid item xs={12} pl={3}>
              <People />
            </Grid>
            <Grid item xs={12} pl={3}>
              <Calendar />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Dashboard;
