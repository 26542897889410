import { Grid, Paper, Box, Button } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const useStyles = makeStyles((theme) => ({
  button: {
    borderWidth: "2px !important",
    borderRadius: "10px !important",
    textTransform: "none !important",
    padding: "10px 30px !important",
    position: "relative",
    top: "50px",
  },
  card: {
    height: "150px",
    borderRadius: "10px !important",
    padding: "10px",
  },
  h4: {
    margin: "0px",
    fontWeight: "400",
  },
}));

const Clinic = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.card}>
            <h4 className={classes.h4}>Heart Rate</h4>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.card}>
            <h4 className={classes.h4}>Blood Pressur</h4>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.card}>
            <h4 className={classes.h4}> Blood Oxygen</h4>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={2} className={classes.card}>
            <h4 className={classes.h4}>Blood Sugar</h4>
          </Paper>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Button
          startIcon={<AddCircleIcon />}
          color="secondary"
          variant="outlined"
          className={classes.button}
        >
          Add a Record
        </Button>
      </Box>
    </>
  );
};

export default Clinic;
