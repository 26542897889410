import React from "react";
import { Box, Paper, Typography, Stack, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WifiCallingIcon from "@mui/icons-material/WifiCalling";
import "./../../../../Assets/Styles/Common/Scrollbar.css";

const useStyles = makeStyles((theme) => ({
  appointments: {
    borderRadius: "10px !important",
  },
  appointmentsList: {
    borderLeft: "10px solid #504DA6",
    borderRadius: "10px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 30px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stack: {
    overflow: "auto",
    height: "340px",
    width: "100%",
    padding: "0 10px 0 10px",
  },
}));
const Appointments = () => {
  const classes = useStyles();
  return (
    <Paper
      elevation={2}
      className={classes.appointments}
      sx={{
        padding: " 20px 30px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" fontWeight="600">
          Upcoming Appointments
        </Typography>
        <Typography
          variant="p"
          fontWeight="500"
          color="primary"
          sx={{ cursor: "pointer" }}
        >
          See All
        </Typography>
      </Box>
      <Box className={` ${"scrollhost"} ${classes.stack}`}>
        <Stack mt={2} spacing={2}>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
          <Paper elevation={2} className={classes.appointmentsList}>
            <Avatar />
            <Typography variant="p" fontWeight="500" color="black">
              Ahemd ahemd
              <Typography fontWeight="500" color="gray" fontSize={15}>
                Ear anatomy
              </Typography>
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              0547221998
            </Typography>
            <Typography variant="p" fontWeight="500" color="black">
              13/12/2022 - 10:00 AM
            </Typography>
            <WifiCallingIcon
              sx={{
                backgroundColor: "#504DA6",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          </Paper>
        </Stack>
      </Box>
    </Paper>
  );
};

export default Appointments;
