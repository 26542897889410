import { Box, InputBase, Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px",
    boxShadow: "0 1px 10px -5px #888",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: "100",
  },
  search: {
    border: "1px solid #4F4DA6",
    padding: "1px ",
    borderRadius: "15px",
    width: "500px",
    margin: "auto",
  },
  admin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.search}>
        <InputBase
          sx={{ fontSize: "15px" }}
          startAdornment={
            <SearchIcon sx={{ color: "grey", padding: " 0 20px" }} />
          }
          placeholder="Search"
        />
      </Box>
      <Box className={classes.admin}>
        <NotificationsRoundedIcon
          sx={{ fontSize: "30px", color: "primary.main" }}
        />
        <Typography padding={2} display="flex" sx={{ cursor: "pointer" }}>
          Mohammed Zuhaib
          <KeyboardArrowDownRoundedIcon />
        </Typography>

        <Avatar sx={{ bgcolor: "#4F4DA6" }}>P</Avatar>
      </Box>
    </Box>
  );
};

export default Header;
