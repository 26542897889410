import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  p: {
    marginTop: "0px",
  },
  h2: {
    fontWeight: "400",
    color: "#504DA6",
    fontSize: "25px",
  },
}));

const Profile = () => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Date Of Birth</p>
          <h3 className={classes.h2}>28-09-2022</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Address</p>
          <h3 className={classes.h2}>Abu Dhabi</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Insurance</p>
          <h3 className={classes.h2}>Daman</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Nationality</p>
          <h3 className={classes.h2}> Emirates</h3>
        </Grid>
      </Grid>
      <Divider sx={{ mb: "0.8rem" }} />
      <Grid container spacing={2}>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Date Of Birth</p>
          <h3 className={classes.h2}>28-09-2022</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Address</p>
          <h3 className={classes.h2}>Abu Dhabi</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Insurance</p>
          <h3 className={classes.h2}>Daman</h3>
        </Grid>
        <Grid textAlign="center" item xs={3}>
          <p className={classes.p}>Nationality</p>
          <h3 className={classes.h2}> Emirates</h3>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
